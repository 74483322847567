import { action } from 'mobx';
import { IProjectLoader } from '../services/ProjectLoader';
import { ProjectSchema } from '../models/schema';
import { getErrorMessage } from '../services/Utils';

export class LoadedProjectStore {
  private projectCodeMaxLength = 6;
  projectDto: ProjectSchema | null = null;

  constructor(private readonly projectLoader: IProjectLoader) {}

  @action
  async loadByProjectCode(projectCode: string): Promise<ProjectSchema> {
    return await this.projectLoader.loadProjectByProjectCode(projectCode);
  }

  async findProjectCode(code: string): Promise<{ result: boolean; projectDto: ProjectSchema | null; error?: string }> {
    function isProjectDto(object: any): object is ProjectSchema {
      return 'doors' in object;
    }

    if (code.length === this.projectCodeMaxLength) {
      try {
        const project: { message: string; statusCode: number } | ProjectSchema = await this.loadByProjectCode(code);
        if (isProjectDto(project)) {
          return { result: true, projectDto: project };
        }
      } catch (e) {
        return { result: false, projectDto: null, error: getErrorMessage(e) };
      }
    }
    return { result: false, projectDto: null, error: 'message.project.code.error' };
  }
}
