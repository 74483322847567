import { action, computed, observable } from 'mobx';

import Localization from '../models/Localization';
import { languageService } from '../services/LanguageService';
import { NotificationsStore } from './NotificationsStore';
import { embeddingService } from '../services/embedding/EmbeddingService';

import { MediaPathResolver } from '../services/MediaPathResolver';
import { ValidationService } from '../services/ValidationService';
import { ProjectRecoveryService } from '../services/ProjectRecoveryService';
import { AvailableFilterFolds, CATEGORY_ALL } from '../models/filtersConstants';

import { IProductEansOption } from '../../../schema';

export enum DeviceType {
  MOBILE,
  TABLET
}

export type DoorShortInfo = {
  ean: string;
  productId: string;
  lineName: string;
  designName: string;
  material: string;
  price: number;
};

/**
 * Serialized project to be stored in localStorage.
 * Ensures the cart is persisted between page reloads.
 *
 * @see AutosaveStoredDoor
 */

export class JokaRootStore {
  projectRecoveryService = new ProjectRecoveryService();
  private _scannedDoors: { productId: string; ean: string }[] = [];
  localization = new Localization();
  @observable isLoading = true;
  @observable deviceType: DeviceType = DeviceType.MOBILE;
  @observable eanScannerOpened = false;
  @observable eanMultiScannerOpened = false;
  @observable scanSelectorOpened = false;
  mediaResolver: MediaPathResolver;
  validationService: ValidationService;

  private eans?: IProductEansOption[] = undefined;
  notificationsStore = new NotificationsStore();
  private selectedCategories = [CATEGORY_ALL];
  private selectedFolds: string[] = [AvailableFilterFolds.All];

  private navigationGoBack: () => void;

  @action.bound
  async init() {
    this.mediaResolver = await MediaPathResolver.create();
    this.isLoading = true;
    await embeddingService.awaitBootstrapping();
    await this.localization.init();
    this.validationService = new ValidationService(this.localization);
    languageService.setLanguage(process.env.REACT_APP_CL__WEBSITE_LANG);

    await embeddingService.sendReady();
    this.isLoading = false;
  }

  //region Basket

  @action.bound
  setDeviceType(type: DeviceType) {
    this.deviceType = type;
  }

  @action.bound
  setOpenedEanScanner(eanScannerOpened: boolean) {
    this.eanScannerOpened = eanScannerOpened;
  }

  @action.bound
  setOpenedScanSelector(scanSelectorOpened: boolean) {
    this.scanSelectorOpened = scanSelectorOpened;
  }

  @action.bound
  setOpenedEanMultiScanner(eanMultiScannerOpened: boolean) {
    this.eanMultiScannerOpened = eanMultiScannerOpened;
  }

  setScannedDoors(doors: Array<{ productId: string; ean: string }>) {
    this._scannedDoors = doors;
  }

  @computed get scannedDoors() {
    return this._scannedDoors;
  }

  setNavigationGoBack(goBack: () => void) {
    this.navigationGoBack = goBack;
  }

  runNavigationGoBack() {
    if (this.navigationGoBack) {
      this.navigationGoBack();
    }
  }

  doorRoom(roomName: string) {
    const { localization } = this;
    return roomName.trim().length > 0 ? roomName : localization.formatMessage('door.room.empty');
  }

  getDoorsCount() {
    return this.projectRecoveryService.doorsInBasket;
  }

  setCategoriesFilter(categoriesFilter: string[]) {
    this.selectedCategories = categoriesFilter;
  }

  setFoldsFilter(foldsFilter: string[]) {
    this.selectedFolds = foldsFilter;
  }

  getFilters(): { categoriesFilter: string[]; foldsFilter: string[] } {
    return { categoriesFilter: this.selectedCategories, foldsFilter: this.selectedFolds };
  }

  async findShortInfoByEan(ean: string): Promise<DoorShortInfo | null> {
    let material = '';
    let price = 0;
    if (!this.eans) {
      await this.fetchEans();
    }
    const product = this.eans!.find(p => {
      for (const productEan of p.eans) {
        if (productEan.ean === ean) {
          material = productEan.material;
          price = productEan.price;
          return p;
        }
      }
    });

    if (product) {
      const productId = product.designPlusLine.replace(/[-+ /\\]/g, '_').toLocaleLowerCase();
      const designPlusLine = product.designPlusLine.split('+');
      const designName = designPlusLine[0];
      const lineName = designPlusLine[1];
      return { ean, productId, lineName, designName, material, price };
    }

    return null;
  }

  async fetchEans() {
    try {
      const eans = await fetch(this.mediaResolver.getPath('media/datasets/joka.eans.json'));
      this.eans = await eans.json();
    } catch (e) {
      console.error(e);
    }
  }
}
