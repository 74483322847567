import { DeviceType, DeviceTypeMessage, InboundMessage, LocationMessage, OrderStatusMessage } from './inboundMessages';
import {
  configuratorReady,
  embedded,
  getHostDeviceType,
  getLocation,
  order,
  OutboundMessage,
  redirect
} from './outboundMessages';
import { deserialize } from './serialized';
import { JokaShopContext } from '../../models/jokaShop/JokaShopContext';
import { JokaShopData } from '../../models/jokaShop/JokaShopData';

export type IFrameMessageType = (InboundMessage | OutboundMessage)['type'];

type IFrameMessageHandler = (inboundMessage: InboundMessage) => void;

type IFrameMessageEventListener<T extends InboundMessage> = {
  once?: boolean;
  handler: IFrameMessageHandler;
};

/**
 * Communication wrapper between iframe host and the application.
 *
 * Message channel: window.postMessage
 * When application is used outside of iframe, no message is sent or handled.
 *
 * @see EmbeddingService.isEmbedded
 * @see window.postMessage
 */
export default class EmbeddingService {
  private readonly ignoreEmbedCheck: boolean;
  private listenerMap = new Map<IFrameMessageType, IFrameMessageEventListener<InboundMessage>>();
  private readonly EMBEDDED_PING_INTERVAL = 100;
  private readonly submitOrderTimeout = 60000;

  /**
   * Decide if we are working from inside the iframe
   */
  get isEmbedded() {
    if (this.ignoreEmbedCheck) {
      return true;
    }

    return window !== window.parent;
  }

  constructor(ignoreEmbedCheck = false) {
    this.ignoreEmbedCheck = ignoreEmbedCheck;

    if (this.isEmbedded) {
      window.addEventListener('message', this.handleMessage);
    }
  }

  /**
   * Awaiting initial message from the host page.
   * Presumably waiting for host to finish initialization.
   */
  async awaitBootstrapping() {
    if (!this.isEmbedded) {
      return;
    }

    const handler = window.setInterval(() => {
      this.sendMessage(embedded());
    }, this.EMBEDDED_PING_INTERVAL);

    await this.awaitEventType('bootstrapped');
    window.clearInterval(handler);
  }

  sendReady() {
    this.sendMessage(configuratorReady());
  }

  redirect(url: string) {
    this.sendMessage(redirect(url));
  }

  async submitOrder(context: JokaShopContext, data: JokaShopData): Promise<void> {
    this.sendMessage(order(context, data));
    return new Promise<void>(async (resolve, reject) => {
      const timeoutHandle = setTimeout(() => {
        reject({
          success: false,
          status: `Request Timeout`,
          error: new Error(`Submit order timeout (${this.submitOrderTimeout})`)
        });
      }, this.submitOrderTimeout);
      const inboundMessage = await this.awaitEventType<OrderStatusMessage>('order_status');
      clearTimeout(timeoutHandle);
      if (inboundMessage.payload.success) {
        resolve();
      } else {
        reject(inboundMessage.payload);
      }
    });
  }

  /**
   * Obtain page location data.
   * Get current window data for standalone version and parent window data for embedded one.
   *
   * @see window.location
   */
  async getLocation(): Promise<Location> {
    if (!this.isEmbedded) {
      return window.location;
    }

    const message = await this.asyncCall<LocationMessage>(getLocation(), 'location');
    const location = deserialize(message.payload.location);
    return location;
  }

  async getHostDeviceType(): Promise<DeviceType> {
    if (!this.isEmbedded) {
      return getDeviceType();
    }

    const message = await this.asyncCall<DeviceTypeMessage>(getHostDeviceType(), 'device_type');
    return message.payload.deviceType;
  }

  /**
   * Send message to parent and wait for specific message in response.
   *
   * @param requestType app -> host request message type
   * @param responseType host -> app response message type
   * @private
   */
  private async asyncCall<T extends InboundMessage>(
    outboundMessage: OutboundMessage,
    responseType: InboundMessage['type']
  ): Promise<T>;
  private async asyncCall(
    outboundMessage: OutboundMessage,
    responseType: InboundMessage['type']
  ): Promise<InboundMessage> {
    this.sendMessage(outboundMessage);
    return this.awaitEventType(responseType);
  }

  sendMessage(outboundMessage: OutboundMessage) {
    if (!this.isEmbedded) {
      return;
    }

    window.parent.postMessage(outboundMessage, '*');
  }

  private async awaitEventType<T extends InboundMessage>(type: T['type']): Promise<T>;
  private async awaitEventType(type: InboundMessage['type']): Promise<InboundMessage> {
    return new Promise(resolve => {
      this.setMessageListener(
        type,
        event => {
          resolve(event);
        },
        true
      );
    });
  }

  private setMessageListener(type: IFrameMessageType, handler: IFrameMessageHandler, once?: boolean) {
    if (this.listenerMap.has(type)) {
      console.warn(`Overriding iframe message listener (type: ${type})`);
    }

    this.listenerMap.set(type, {
      handler,
      once
    });
  }

  private removeMessageListener(type: IFrameMessageType) {
    this.listenerMap.delete(type);
  }

  private handleMessage = (event: MessageEvent) => {
    const { data } = event;
    const inboundMessage: InboundMessage = data as InboundMessage;
    const type = inboundMessage.type;

    const listener = this.listenerMap.get(type);

    if (listener === undefined) {
      console.warn(`Unhandled iframe message (type: ${type})`);
      return;
    }

    listener.handler(inboundMessage);

    if (listener.once) {
      this.removeMessageListener(type);
    }
  };

  dispose() {
    window.removeEventListener('message', this.handleMessage);
  }
}

function getDeviceType() {
  const query = window.matchMedia('only screen and (max-width: 600px)');
  const isMobile = query && query.matches;
  return isMobile ? 'mobile' : 'desktop';
}

export const embeddingService = new EmbeddingService();
