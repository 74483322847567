import Localization from '../models/Localization';
import { ProjectSchema } from '../models/schema';

export interface IProjectLoader {
  loadProjectByProjectCode(projectCode: string): Promise<ProjectSchema>;
}

export class ProjectLoader implements IProjectLoader {
  readonly projectUrl = process.env.REACT_APP_CL__BACKEND_SERVICE_URL;

  constructor(private readonly localization: Localization) {}

  async loadProjectByProjectCode(projectCode: string): Promise<ProjectSchema> {
    const url = `${this.projectUrl}/projects/${projectCode}`;
    try {
      const response = await fetch(url);
      const projectDto: ProjectSchema = await response.json();
      return projectDto;
    } catch (e) {
      const message = this.localization.formatMessage('message.project.loading_by_code.failed');
      throw new Error(message);
    }
  }
}
