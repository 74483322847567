export enum JokaShopContext {
  Staging = 'staging',
  Dev = 'dev',
  Prod = 'prod'
}

export function getContextUrl(context: JokaShopContext): string {
  switch (context) {
    // case JokaShopContext.Staging:
    //   return 'https://stage.jordanshop.de/isapi/v1/configurator/addtocart';
    case JokaShopContext.Dev:
      return 'https://edi-test.joka.de/dw/Request/';
    case JokaShopContext.Prod:
      return 'https://edi.joka.de/dw/Request/config2basket';
    default:
      return 'https://edi-test.joka.de/dw/Request/config2basket';
  }
}
