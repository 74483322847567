import './types.d.ts';
import gzip from 'gzip-js';
import pako from 'pako';

/**
 * To use this function you need:
 * const promiseFunc = async () => {
 *  const textResult = await fetch();
 *  return new Blob([textResult], { type: 'text/plain' });
 * };
 * const copyResult = await copyTextToClipboardAfterAsyncGetter(promiseFunc());
 */
export async function copyTextToClipboardAfterAsyncGetter(
  text: string | Promise<Blob>
): Promise<{ status: 'success' | 'notEnabledToCopy' | 'notSupportedToCopy' | 'failedToCopy' }> {
  const id = 'e2e-clipboard';
  let clipboardHolder = document.getElementById(id) as HTMLTextAreaElement;
  if (!clipboardHolder) {
    clipboardHolder = document.createElement('textarea');
    clipboardHolder.setAttribute('id', id);
    clipboardHolder.style.zIndex = '-1';
    clipboardHolder.style.width = '0';
    clipboardHolder.style.height = '0';
    clipboardHolder.style.top = '0';
    clipboardHolder.style.left = '0';
    clipboardHolder.style.position = 'fixed';
  }
  clipboardHolder.readOnly = false;
  clipboardHolder.value = typeof text === 'string' ? text : await (await text).text();
  document.body.appendChild(clipboardHolder);

  clipboardHolder.readOnly = true;
  if (!navigator.clipboard) {
    clipboardHolder.select();
    if (!document.queryCommandSupported('copy')) {
      return { status: 'notSupportedToCopy' };
    }
    if (!document.queryCommandEnabled('copy')) {
      return { status: 'notEnabledToCopy' };
    }

    const result = document.execCommand('copy');
    if (!result) {
      return { status: 'failedToCopy' };
    }
  } else {
    const linkBlob = typeof text === 'string' ? new Blob([text], { type: 'text/plain' }) : text;
    await navigator.clipboard.write([new ClipboardItem({ 'text/plain': linkBlob })]);
  }
  return { status: 'success' };
}

export async function copyTextToClipboard(text: string) {
  const id = 'e2e-clipboard';
  let clipboardHolder = document.getElementById(id) as HTMLTextAreaElement;
  if (!clipboardHolder) {
    clipboardHolder = document.createElement('textarea');
    clipboardHolder.setAttribute('id', id);
    clipboardHolder.style.display = 'none';
    document.body.appendChild(clipboardHolder);
  }
  clipboardHolder.value = text;
  return new Promise<{ status: 'success' | 'notEnabledToCopy' | 'notSupportedToCopy' | 'failedToCopy' }>(resolve => {
    setTimeout(() => {
      const tmpElement = document.createElement('textarea');
      tmpElement.value = text;
      document.body.appendChild(tmpElement);
      tmpElement.select();

      if (!document.queryCommandSupported('copy')) {
        resolve({ status: 'notSupportedToCopy' });
      }
      if (!document.queryCommandEnabled('copy')) {
        resolve({ status: 'notEnabledToCopy' });
      }

      const result = document.execCommand('copy');
      document.body.removeChild(tmpElement);
      if (!result) {
        resolve({ status: 'failedToCopy' });
      }

      resolve({ status: 'success' });
    }, 1);
  });
}

export function gzipObject(data: any) {
  const str = JSON.stringify(data);
  const deflated = pako.deflateRaw(str, { level: 9 });

  let deflatedString = '';
  for (let i = 0; i < deflated.length; i++) {
    deflatedString += String.fromCharCode(deflated[i]);
  }

  const encoded = btoa(deflatedString);
  return encoded;
}

export function unGzipObjectV5(data: string) {
  const unzipped = gzip.unzip(Array.from(atob(data)).map(u => u.charCodeAt(0)));
  const unzippedString = unzipped.map(d => String.fromCharCode(d)).join('');
  return JSON.parse(unzippedString);
}

export function unGzipObject(data: string) {
  const binaryString = atob(data);

  // Create a byte array of the correct size
  const byteArray = new Uint8Array(binaryString.length);

  // Populate the byte array with the binary data
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  const inflated = pako.inflateRaw(byteArray, { to: 'string' });
  return JSON.parse(inflated);
}

export function queryString(search: string) {
  if (search[0] === '?') {
    search = search.slice(1);
  }

  return search
    .split('&')
    .map(pair => pair.split('='))
    .reduce((acc, next) => {
      acc[next[0]] = next[1];
      return acc;
    }, {} as any);
}

export function throttle<T extends (...args: any[]) => void>(func: T, timeout: number): T {
  let ready = true;
  return function (...args: any[]) {
    if (!ready) {
      return;
    }

    ready = false;
    func(...args);
    setTimeout(() => {
      ready = true;
    }, timeout);
  } as T;
}

export function numericCompare(a: any, b: any): number {
  if (typeof a === 'string') {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
  }

  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
}

export function normalizeUrl(url: string): string {
  if (url.slice(-1) === '/') {
    return url;
  }
  return url + '/';
}

function normalizePath(path: string): string {
  if (path.slice(0) === '/') {
    return path.slice(1);
  }
  return path;
}
export function createUrl(path: string): string {
  return window.location.origin + normalizeUrl(process.env.PUBLIC_URL) + normalizePath(path);
}

export function getErrorMessage(e: unknown): string {
  if (e instanceof Error) {
    return e.message;
  }
  return String(e);
}
