import { observable } from 'mobx';
import { ProjectSchema } from '../models/schema';
import { SERIALIZATION_VERSION } from '../consts';

export type AutosaveStoredProject = {
  timestamp: number;
  version: number;
  data: ProjectSchema;
};

export class ProjectRecoveryService {
  private static readonly PROJECT_STORAGE_KEY = 'PROJECT';
  private static readonly PROJECT_STORAGE_LIFESPAN = 1_209_600_000; // 2 weeks

  public hasProject = false;
  public projectData: AutosaveStoredProject['data'] | undefined = undefined;
  @observable public doorsInBasket = 0;

  constructor() {
    const storedJSON = localStorage.getItem(ProjectRecoveryService.PROJECT_STORAGE_KEY);
    if (storedJSON) {
      const storedProject = JSON.parse(storedJSON) as AutosaveStoredProject;

      if (
        storedProject.version === SERIALIZATION_VERSION &&
        storedProject.timestamp + ProjectRecoveryService.PROJECT_STORAGE_LIFESPAN > Date.now()
      ) {
        this.hasProject = true;
        this.projectData = storedProject.data;
        this.doorsInBasket = storedProject.data.doors.length;
      }
    }
  }

  clearData() {
    localStorage.removeItem(ProjectRecoveryService.PROJECT_STORAGE_KEY);
    this.doorsInBasket = 0;
  }

  updateData(projectSchema: ProjectSchema) {
    const storedProject: AutosaveStoredProject = {
      timestamp: Date.now(),
      version: SERIALIZATION_VERSION,
      data: projectSchema
    };
    this.doorsInBasket = projectSchema.doors.length;
    localStorage.setItem(ProjectRecoveryService.PROJECT_STORAGE_KEY, JSON.stringify(storedProject));
  }
}
