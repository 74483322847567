type Name = string;
type Path = string;

export class MediaPathResolver {
  static fileName = 'media-manifest.json';

  private mapping: Record<Name, Path> = {};

  static async create(): Promise<MediaPathResolver> {
    const loader = new MediaPathResolver();
    await loader.init();
    return loader;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  getPath(name: string): string {
    return this.mapping[name] ?? name;
  }

  private async init() {
    try {
      const response = await fetch(MediaPathResolver.fileName);
      this.mapping = await response.json();
    } catch (e) {
      throw new Error(`Cannot load ${MediaPathResolver.fileName}`);
    }
  }
}
