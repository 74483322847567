import * as React from 'react';

interface IProps {
  size: number;
}

export function Loader(props: IProps) {
  const size = props.size ?? 24;
  const loader = `
   <svg id="loader_spinner" xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 40 40">
                <style>
                  @keyframes a0_t {
                    0% {
                      transform: translate(20px, 20px) rotate(0deg);
                    }
                    100% {
                      transform: translate(20px, 20px) rotate(360deg);
                    }
                  }

                  @keyframes a0_da {
                    0% {
                      stroke-dasharray: 50px;
                    }
                    100% {
                      stroke-dasharray: 50px;
                    }
                  }

                  @keyframes a0_do {
                    0% {
                      stroke-dashoffset: 0px;
                    }
                    100% {
                      stroke-dashoffset: -100px;
                    }
                  }
                </style>
                <ellipse rx="10" ry="10" fill="none" stroke="#3a3f45" stroke-width="2" stroke-linejoin="round"
                         stroke-dasharray="50" transform="translate(20,20)"
                         style="animation: 1.5s linear infinite both a0_t, 3s linear infinite both a0_da, 3s linear infinite both a0_do;"/>
            </svg>
  `;
  return <div dangerouslySetInnerHTML={{ __html: loader }} />;
}
