import * as React from 'react';
import { action, observable } from 'mobx';
import { createIntl, createIntlCache, defineMessages, FormattedHTMLMessage, IntlShape } from 'react-intl';

export enum Locale {
  en = 'en',
  de = 'de'
}

type LocaleMessages = Record<string, string>;

const defaultLocale = process.env.REACT_APP_CL__WEBSITE_LANG;

class Localization {
  @observable locale = defaultLocale;
  @observable intl: IntlShape;

  @action
  async init() {
    const messages = await this.fetchTranslation();
    this.initIntl(messages);
  }

  @action
  private async fetchTranslation() {
    const url = `assets/i18n/${this.locale}.json`;
    try {
      const response = await fetch(url);
      return (await response.json()) as LocaleMessages;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  @action
  async changeLanguage() {
    this.locale = this.locale === Locale.de ? Locale.en : Locale.de;
    this.init();
  }

  formatMessage(key: string, values?: Record<string, any>) {
    if (!this.intl) {
      console.warn("Localization didn't init");
      return key;
    }

    const messages = defineMessages({
      [key]: {
        id: key
      }
    });

    return this.intl.formatMessage(messages[key], values);
  }

  formatHTMLMessage(key: string, values?: Record<string, any>) {
    return <FormattedHTMLMessage id={key} values={values} />;
  }

  private initIntl(messages: LocaleMessages) {
    // This is optional but highly recommended
    // since it prevents memory leak
    const cache = createIntlCache();

    this.intl = createIntl(
      {
        locale: this.locale,
        messages: messages,
        textComponent: 'span'
      },
      cache
    );
  }
}

export default Localization;
