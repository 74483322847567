export const ROUTES = {
  start: '/catalog/start',
  doorLine: '/catalog/doorLine',
  doorDesign: '/catalog/:productGroup/doorDesign',
  cart: '/core/cart',
  projectCart: '/core/cart/:projectCode',
  creator: '/core/new/:productId',
  editor: '/core/edit/:doorId',
  sharedDoor: '/core/sharedDoor/',
  sharedProject: '/core/sharedProject/',
  creatorByEan: '/core/ean/:productId/:ean'
};

export function doorDesignLink(id: string): string {
  return ROUTES.doorDesign.replace(/:productGroup/, id);
}

export function creatorLink(id: string): string {
  return ROUTES.creator.replace(/:productId/, id);
}

export function creatorByEanLink(id: string, ean: string): string {
  return ROUTES.creatorByEan.replace(/:productId/, id).replace(/:ean/, ean);
}

export function editorLink(id: string): string {
  return ROUTES.editor.replace(/:doorId/, id);
}

export function projectCartLink(code: string): string {
  return ROUTES.projectCart.replace(/:projectCode/, code);
}
