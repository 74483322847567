import * as React from 'react';
import { Route, Switch } from 'react-router';
import { RawIntlProvider } from 'react-intl';
import { observer } from 'mobx-react';

import { DeviceType } from './modules/shared/stores/JokaRootStore';
import { LoadingMask } from './modules/shared/components/LoadingMask/LoadingMask';

import './App.scss';
import { rootStore } from './modules/shared/stores';
import { BackwardCompatibilityRoutes } from './modules/shared/components/BackwardCompatibilityRoutes/BackwardCompatibilityRoutes';

const CatalogRouter = React.lazy(() => import(/* webpackChunkName: "[catalog]" */ './modules/catalog/router'));
const CoreRouter = React.lazy(() => import(/* webpackChunkName: "[core]" */ './modules/core/router'));

// eslint-disable-next-line @typescript-eslint/ban-types
type NoProps = {};

const CoreRouterSuspensed = () => (
  <React.Suspense fallback={<Route render={() => <LoadingMask />} />}>
    <CoreRouter />
  </React.Suspense>
);

const CatalogRouterSuspensed = () => (
  <React.Suspense fallback={<Route render={() => <LoadingMask />} />}>
    <CatalogRouter />
  </React.Suspense>
);

@observer
class App extends React.Component {
  private readonly mediaQueryList: MediaQueryList;

  constructor(props: NoProps) {
    super(props);

    /**
     * @todo unify this media query with the one used by SCSS
     */
    this.mediaQueryList = window.matchMedia('only screen and (min-width: 1024px)');
    this.handleDeviceTypeChanged(this.mediaQueryList.matches);
  }

  async componentDidMount() {
    await rootStore.init();

    if (this.mediaQueryList.addEventListener) {
      this.mediaQueryList.addEventListener('change', this.handleMediaChanged);
    } else {
      // Safari does not treat MediaQueryList as EventTarget yet
      this.mediaQueryList.addListener(this.handleMediaChanged);
    }
  }

  async componentWillUnmount() {
    if (this.mediaQueryList.removeEventListener) {
      this.mediaQueryList.removeEventListener('change', this.handleMediaChanged);
    } else {
      // Safari does not treat MediaQueryList as EventTarget yet
      this.mediaQueryList.removeListener(this.handleMediaChanged);
    }
  }

  handleMediaChanged = (event: MediaQueryListEvent) => {
    this.handleDeviceTypeChanged(event.matches);
  };

  handleDeviceTypeChanged = (matchesTabletAndBigger: boolean) => {
    rootStore.setDeviceType(matchesTabletAndBigger ? DeviceType.TABLET : DeviceType.MOBILE);
  };

  render() {
    const { isLoading, localization } = rootStore;

    const testableFeature = process.env.REACT_APP_CL__TESTABLE_FEATURE ?? '';
    return isLoading ? (
      <LoadingMask />
    ) : (
      <div className="layout">
        <RawIntlProvider value={localization.intl}>
          <Switch>
            <Route path="/catalog" component={CatalogRouterSuspensed} />
            <Route path="/core" component={CoreRouterSuspensed} />
            <Route component={BackwardCompatibilityRoutes} />
          </Switch>
        </RawIntlProvider>
        {testableFeature && <div className="testableFeature">{testableFeature}</div>}
      </div>
    );
  }
}

export default App;
