import React from 'react';

import style from './LoadingMask.module.scss';
import { Loader } from './Loader';
import { dataCy } from '../../../../cy';

export class LoadingMask extends React.Component {
  render() {
    return (
      <div className={style.root}>
        <Loader size={100} />
      </div>
    );
  }
}
