import Localization from '../models/Localization';
import isEmail from 'validator/es/lib/isEmail';

export class ValidationService {
  constructor(private readonly localization: Localization) {}

  notEmpty = (value: string) =>
    value.trim().length > 0 ? null : this.localization.formatMessage('validation.notEmpty');

  numeric = (value: string) => (/^\d+$/.test(value) ? null : this.localization.formatMessage('validation.numbers'));
  isEmail = (value: string) => (isEmail(value) ? null : this.localization.formatMessage('validation.email'));
  maxLength = (value: string, maxLength: number) =>
    value.length < maxLength + 1
      ? null
      : this.localization.formatMessage('validation.maxLength', { maxLength: maxLength });

  lengthEqualTo = (value: string, length: number) =>
    value.length === length ? null : this.localization.formatMessage('validation.lengthEqualTo', { length: length });
}
