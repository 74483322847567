import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { ROUTES } from '../../models/routes';

interface IProps {
  from: string;
  to: string;
}

class RedirectWithQueryParams extends React.Component<IProps> {
  render() {
    const { from, to } = this.props;
    return (
      <Route
        path={from}
        component={(props: RouteComponentProps) => {
          const url = `${to}${props.location.search}`;
          return <Redirect to={url} />;
        }}
      />
    );
  }
}

export class BackwardCompatibilityRoutes extends React.Component<RouteComponentProps> {
  render() {
    return (
      <Switch>
        <RedirectWithQueryParams from="/sharedDoor/" to={ROUTES.sharedDoor} />
        <RedirectWithQueryParams from="/sharedProject/" to={ROUTES.sharedProject} />
        <Redirect to={ROUTES.doorLine} />
      </Switch>
    );
  }
}
