import { JokaShopData } from '../../models/jokaShop/JokaShopData';
import { getContextUrl, JokaShopContext } from '../../models/jokaShop/JokaShopContext';

export interface EmbeddedMessage {
  type: 'embedded';
}

export interface GetLocationMessage {
  type: 'getLocation';
}

export interface RedirectMessage {
  type: 'redirect';
  payload: {
    url: string;
  };
}

export interface OrderMessage {
  type: 'order';
  payload: {
    postUrl: string;
    body: JokaShopData;
    redirectUrl: string;
  };
}

export interface ConfiguratorReadyMessage {
  type: 'configurator_ready';
}

export interface GetDeviceTypeMessage {
  type: 'getDeviceType';
}

export type OutboundMessage =
  | EmbeddedMessage
  | GetLocationMessage
  | ConfiguratorReadyMessage
  | RedirectMessage
  | GetDeviceTypeMessage
  | OrderMessage;

export const embedded = (): EmbeddedMessage => ({ type: 'embedded' });
export const configuratorReady = (): ConfiguratorReadyMessage => ({ type: 'configurator_ready' });
export const redirect = (url: string): RedirectMessage => ({ type: 'redirect', payload: { url } });
export const getLocation = (): GetLocationMessage => ({ type: 'getLocation' });
export const getHostDeviceType = (): GetDeviceTypeMessage => ({ type: 'getDeviceType' });
export const order = (context: JokaShopContext, data: JokaShopData): OrderMessage => ({
  type: 'order',
  payload: {
    body: data,
    postUrl: getContextUrl(context),
    redirectUrl: CART_URL
  }
});
const CART_URL = '/Warenkorb';
